import { testTypes } from '../testTypes';
import { TestCardStatus } from '..';

type TestStatus = Partial<Record<TestCardStatus, string | null>>;

const testStatuses: Record<string, TestStatus> = {
  [testTypes.COVID_ANTIBODIES]: {
    incomplete: null,
    waiting: null,
    in_progress: null,
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.COVID_BOOSTER]: {
    incomplete: null,
    waiting: null,
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.in_progress.1-3',
    pharmacy_report_pending: 'dashboard.test.status.in_progress.1-3',
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.COVID_IMMUNITY]: {
    incomplete: null,
    waiting: null,
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.in_progress.1-3',
    pharmacy_report_pending: 'dashboard.test.status.in_progress.1-3',
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.COVID_PCR]: {
    incomplete: null,
    waiting: null,
    in_progress: null,
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.HEALTH_CHECK_VEGAN_AND_VEGETARIAN]: {
    incomplete: null,
    waiting: null,
    in_progress: null,
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.HEALTH_CHECK_VEGAN_AND_VEGETARIAN_V2]: {
    incomplete: null,
    waiting: null,
    in_progress: null,
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.HEALTH_CHECK_ATHLETES]: {
    incomplete: null,
    waiting: null,
    in_progress: null,
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.URINARY_TRACT_INFECTION]: {
    incomplete: null,
    waiting: null,
    in_progress: null,
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.IMMUNOTHERAPY_CHECK_POLLEN]: {
    incomplete: null,
    waiting: null,
    in_progress: null,
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.MONKEY_POX]: {
    incomplete: null,
    waiting: null,
    in_progress: null,
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.STD_PREMIUM]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.in_progress.1-3',
    pharmacy_report_pending: 'dashboard.test.status.in_progress.1-3',
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.STD_BASICS_MALE]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-2',
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.STD_BASICS_FEMALE]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-2',
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.STD_BASICS_ORAL]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-2',
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.STD_BASICS_ANAL]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-2',
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.IRON]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-2',
    doctor_report_pending: null,
    pharmacy_report_pending:
      'dashboard.test.status.pharmacy_report_pending.1-2',
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.IRON_V2]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-2',
    doctor_report_pending: null,
    pharmacy_report_pending:
      'dashboard.test.status.pharmacy_report_pending.1-2',
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.VITAMIN_B12]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: null,
    pharmacy_report_pending:
      'dashboard.test.status.pharmacy_report_pending.1-2',
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.VITAMIN_D]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: null,
    pharmacy_report_pending:
      'dashboard.test.status.pharmacy_report_pending.1-2',
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.NUTRITION_360]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-2',
    doctor_report_pending: null,
    pharmacy_report_pending:
      'dashboard.test.status.pharmacy_report_pending.1-2',
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.NUTRITION_360_V2]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-2',
    doctor_report_pending: null,
    pharmacy_report_pending:
      'dashboard.test.status.pharmacy_report_pending.1-2',
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.FATIGUE]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.doctor_report_pending.1-2',
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.FATIGUE_V2]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.doctor_report_pending.1-2',
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.FATIGUE_V3]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.doctor_report_pending.1-2',
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.HEALTH_CHECK_BASIC]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.doctor_report_pending.1-2',
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.HEALTH_CHECK_BASIC_V2]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.doctor_report_pending.1-2',
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.HEALTH_CHECK_BASIC_V3]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.doctor_report_pending.1-2',
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.BASIC_ALLERGY_AND_HAYFEVER_CHECK]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.3-5',
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.ALLERGY_360]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.4-6',
    doctor_report_pending: null,
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.ALLERGY_CHECK_FOR_IMMUNOTHERAPY]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.4-6',
    doctor_report_pending: 'dashboard.test.status.doctor_report_pending.1-2',
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.ADVANCED_IRON]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.doctor_report_pending.1-2',
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.HEALTH_RISK_CHECK]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.1-3',
    doctor_report_pending: 'dashboard.test.status.doctor_report_pending.1-2',
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  },
  [testTypes.COLON_CANCER]: {
    incomplete: 'dashboard.test.status.incomplete',
    waiting: 'dashboard.test.status.waiting',
    in_progress: 'dashboard.test.status.in_progress.2-5',
    doctor_report_pending: 'dashboard.test.status.doctor_report_pending.1-2',
    pharmacy_report_pending: null,
    completed: 'dashboard.test.status.completed'
  }
};

function getStatusMessage(testType: string, status: TestCardStatus): string {
  const testStatus = testStatuses[testType];
  if (!testStatus) {
    //TODO: report error to slack
  }
  return testStatus[status] || '-';
}

export { getStatusMessage };
