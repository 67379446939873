import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import AnswerCard from '../AnswerCard';

import './index.scss';

const MAIN_SLIDER_NAME = 'slide-content__children';

const getInitialState = question => {
  const answers = question.answerOptions.filter(item => item.isSelected === true);
  return answers.map(item => item.key);
};

const handleAnswerClick = (kind, setAnswer, answer, setCurrentScreenAnswer) => newValue => {
  if (kind === 'singleSelect') {
    setAnswer([newValue]);
    setCurrentScreenAnswer([newValue]);
  }

  if (kind === 'multiSelect') {
    if (answer.includes(newValue)) {
      const valueArray = [...answer];
      const valueIndex = valueArray.indexOf(newValue);
      valueArray.splice(valueIndex, 1);
      setAnswer(valueArray);
      setCurrentScreenAnswer(valueArray);
    } else {
      setAnswer([...answer, newValue]);
      setCurrentScreenAnswer([...answer, newValue]);
    }
  }
};

const renderQuestionAnswers = (setCurrentScreenAnswer, answers, setAnswer, answer, kind, handleInfoIconClick) => {
  const numberOfQuestions = answers.length;

  return answers.map(answerData => {
    return (
      <AnswerCard
        questionType={kind}
        key={answerData.key}
        handleAnswerClick={handleAnswerClick(kind, setAnswer, answer, setCurrentScreenAnswer)}
        answerData={answerData}
        currentAnswer={answer}
        numberOfQuestions={numberOfQuestions}
        handleInfoIconClick={handleInfoIconClick}
      />
    );
  });
};

const modalToggle = (isModalOpen, setIsModalOpen) => () => {
  setIsModalOpen(!isModalOpen);
};

const handleInfoIconClick = (isModalOpen, setIsModalOpen, setModalData) => data => {
  modalToggle(isModalOpen, setIsModalOpen)();
  setModalData(data);
};

const Question = ({ data, isModalOpen, setIsModalOpen, setModalData, setCurrentScreenAnswer }) => {
  const [answer, setAnswer] = useState([]);
  const { answerOptions, kind } = data.question;
  const questionElement = useRef(null);

  useEffect(() => {
    const initialState = getInitialState(data.question);
    const parentClass = questionElement.current.parentNode.classList[0];
    // Check if this element is in main slider
    const isInMainSlide = parentClass === MAIN_SLIDER_NAME;
    if (initialState.length > 0 && isInMainSlide) {
      setAnswer(initialState);
      // Parent's answer
      setCurrentScreenAnswer(initialState);
    }

    return () => {
      setAnswer([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setCurrentScreenAnswer]);

  const questionClasses = cx({
    question: true
  });

  const questionAnswersClasses = cx({
    question__answers: true,
    'question__answers--less-then-tree': answerOptions.length < 3
  });

  return (
    <div className={questionClasses} ref={questionElement}>
      <div className={questionAnswersClasses}>
        {renderQuestionAnswers(
          setCurrentScreenAnswer,
          answerOptions,
          setAnswer,
          answer,
          kind,
          handleInfoIconClick(isModalOpen, setIsModalOpen, setModalData)
        )}
      </div>
    </div>
  );
};

Question.propTypes = {
  data: PropTypes.object,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setModalData: PropTypes.func,
  // Setting state of answer in parent component
  setCurrentScreenAnswer: PropTypes.func
};

export default Question;
