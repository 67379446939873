import React from 'react';
import PropTypes from 'prop-types';
import SlideContent from '../SlideContent';
import cx from 'classnames';
import InfoIcon from '@material-ui/icons/Info';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import './index.scss';

const PageTitle = ({
  text,
  isSticky,
  animationDirection,
  changingData,
  mainDescription,
  isModalOpen,
  setIsModalOpen,
  setModalData
}) => {
  const pageTitleClasses = cx({
    'page-title': true,
    'page-title--sticky': isSticky
  });

  const renderModalContent = () => {
    return (
      <div className='page-title__modal-content'>
        <span className='page-title__modal-title'>{text}</span>
        <span className='page-title__modal-description'>
          <Markdown rehypePlugins={[rehypeRaw]}>{mainDescription}</Markdown>
        </span>
      </div>
    );
  };

  const modalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleInfoIconClick = data => {
    modalToggle();
    setModalData(data);
  };

  return (
    <div className={pageTitleClasses}>
      <SlideContent
        animationDirection={animationDirection}
        changingData={changingData}
      >
        <h1 className='page-title__text'>
          {text}
          {mainDescription && (
            <button
              onClick={() => {
                handleInfoIconClick(renderModalContent());
              }}
              className='page-title__info'
              target='_blank'
              rel='noopener noreferrer'
            >
              <InfoIcon className='page-title__info-icon' />
            </button>
          )}
        </h1>
      </SlideContent>
    </div>
  );
};

PageTitle.propTypes = {
  text: PropTypes.string,
  isSticky: PropTypes.bool,
  animationDirection: PropTypes.string,
  changingData: PropTypes.string,
  mainDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setModalData: PropTypes.func
};

export default PageTitle;
