import { useState, useEffect, useRef } from 'react';

export const ActiveTab = {
  doctor: 'doctor',
  allergy: 'allergy',
  immunotherapy: 'immunotherapy',
  medication: 'medication',
  stauffacher: "stauffacher",
  css: "css",
  actions: 'actions'
};

const SECTION_POSITION_OFFSET = 200;

const handleLinks = data => {
  const {
    reports,
    recommendedActions,
    allergensData,
    immunotherapyEligibilityData,
    partnerBlocks,
    recommendedMedication,
    overallResult
  } = data;

  let links = [];

  if (reports.doctorReport) {
    links.push({
      textId: "app.doctors-report",
      sectionsId: ActiveTab.doctor
    });
  }
  if (allergensData) {
    links.push({
      textId: "test-result.allergy-diagnosis",
      sectionsId: ActiveTab.allergy
    });
  }
  if (overallResult === 'positive' && immunotherapyEligibilityData) {
    links.push({
      textId: "test-result.eligibility-for-immunotherapy",
      sectionsId: ActiveTab.immunotherapy
    });
  }
  if (recommendedMedication && recommendedMedication.length > 0) {
    links.push({
      textId: "test-result.recommended-medication",
      sectionsId: ActiveTab.medication
    });
  }
  if (partnerBlocks && partnerBlocks.stauffacherBlock) {
    links.push({
      textId: "test-result.partner-code.stauffacher-support",
      sectionsId: ActiveTab.stauffacher
    });
  }
  if (partnerBlocks && partnerBlocks.CSSBlock) {
    links.push({
      textId: "test-result.partner-code.css-support",
      sectionsId: ActiveTab.css
    });
  }
  if (recommendedActions) {
    links.push({
      textId: "app.recommended-actions",
      sectionsId: ActiveTab.actions
    });
  }
  return links;
};

const useScrollHandlerForImmunotherapy = ({ data }) => {
  const linksData = handleLinks(data);
  const [activeSection, setActiveSection] = useState(linksData[0].sectionsId);

  const scrollableElementRef = useRef(null);

  const handleScroll = scrollTop => {
    const doctorsReportPosition = document.getElementById('doctor')?.offsetTop;
    const allergyPosition = document.getElementById('allergy')?.offsetTop;
    const immunotherapyPosition = document.getElementById('immunotherapy')?.offsetTop;
    const medicationPosition = document.getElementById('medication')?.offsetTop;
    const stauffacherPosition = document.getElementById('stauffacher')?.offsetTop;
    const cssPosition = document.getElementById('css')?.offsetTop;
    const recommendedActionsPosition = document.getElementById('actions')?.offsetTop;

    const positions = [
      { id: ActiveTab.doctor, position: doctorsReportPosition },
      { id: ActiveTab.allergy, position: allergyPosition },
      { id: ActiveTab.immunotherapy, position: immunotherapyPosition },
      { id: ActiveTab.medication, position: medicationPosition },
      { id: ActiveTab.stauffacher, position: stauffacherPosition },
      { id: ActiveTab.css, position: cssPosition },
      { id: ActiveTab.actions, position: recommendedActionsPosition }
    ].filter(pos => pos.position !== undefined);

    positions.sort((a, b) => a.position - b.position);

    for (let i = 0; i < positions.length; i++) {
      const { id, position } = positions[i];
      //TODO: for now this, later we change it so we dont use Infinity
      const nextPosition = positions[i + 1]?.position ?? Infinity;

      if (scrollTop >= position - SECTION_POSITION_OFFSET && scrollTop < nextPosition - SECTION_POSITION_OFFSET) {
        if (activeSection !== id) {
          setActiveSection(id);
        }
        break;
      }
    }
  };

  useEffect(() => {
    const scrollableElement = scrollableElementRef.current;

    const handleScrollEvent = () => {
      handleScroll(scrollableElement?.scrollTop, activeSection);
    };

    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScrollEvent);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScrollEvent);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);

  return {
    scrollableElementRef,
    activeSection,
    linksData
  };
};

export default useScrollHandlerForImmunotherapy;
