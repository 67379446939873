import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import LanguageDropdown from '../Header/LanguageDropdown';
import MyHealthHeaderNavbar from '../../app/components/MyHealthHeaderNavbar';
import { Body2 } from '../Typography';

import useAuthorization from '../../app/hooks/useAuthorization';
import useClickOutside from '../Header/useClickOutside.hook';

import { ReactComponent as UserIcon } from '../../assets/storybook-placeholders/svg/user-icon.svg';
import { ReactComponent as LogoIcon } from '../../assets/svg/logo-icon.svg';
import { ReactComponent as BackIcon } from '../../assets/svg/arrow-left-icon.svg';

import styles from './index.module.scss';

export interface MyHealthHeaderProps {
  availableLanguages: string[];
  defaultLanguage: string;
  onChangeLanguage: (selectedLanguage: string) => void;
  dispatch?: any;
  backToMyHealth?: () => void;
  className?: string;
  logoClassName?: string;
}

const MyHealthHeader: React.FC<MyHealthHeaderProps> = ({
  availableLanguages,
  defaultLanguage,
  onChangeLanguage,
  dispatch,
  backToMyHealth,
  className,
  logoClassName
}) => {
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false);

  const { signOutUser } = useAuthorization(dispatch);

  const dropdownRef = useRef<HTMLButtonElement>(null);

  useClickOutside(dropdownRef, () => setIsUserDropdownOpen(false));

  const handleUserOpen = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  const handleLogout = () => {
    signOutUser();
  };

  return (
    <div
      className={cx(
        {
          [styles.wrapper]: true
        },
        className && { [className]: true }
      )}
    >
      {backToMyHealth && (
        <button onClick={backToMyHealth} className={styles.backIcon}>
          <BackIcon />
        </button>
      )}

      <LogoIcon className={cx(logoClassName)} />

      <MyHealthHeaderNavbar />

      <div className={styles.buttonsWrapper}>
        <LanguageDropdown
          defaultLanguage={defaultLanguage}
          availableLanguages={availableLanguages}
          onChangeLanguage={onChangeLanguage}
        />
        <button
          onClick={handleUserOpen}
          className={styles.userWrapper}
          ref={dropdownRef}
        >
          <UserIcon />
        </button>
        {isUserDropdownOpen && (
          <button onClick={handleLogout} className={styles.userDropdown}>
            <Body2 className={styles.userDropdownItem}>
              <FormattedMessage id='app.logout' defaultMessage='Logout' />
            </Body2>
          </button>
        )}
      </div>
    </div>
  );
};

export default MyHealthHeader;
