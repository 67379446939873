import { testTypes } from '../testTypes';

export const testTypeNames: Record<string, string> = {
  [testTypes.ALLERGY_360]: '360° Allergy Check',
  [testTypes.VITAMIN_B12]: 'Vitamin B12 Tracking',
  [testTypes.COVID_BOOSTER]: 'COVID Vaccination Check',
  [testTypes.COVID_IMMUNITY]: 'COVID Immunity Management',
  [testTypes.COVID_ANTIBODIES]: 'COVID Antibody Test',
  [testTypes.COVID_PCR]: 'COVID PCR Test',
  [testTypes.VITAMIN_D]: 'Vitamin D Tracking',
  [testTypes.FATIGUE]: '360° Fatigue Check',
  [testTypes.FATIGUE_V2]: '360° Fatigue Check',
  [testTypes.FATIGUE_V3]: '360° Fatigue Check',
  [testTypes.HAYFEVER]: 'Hayfever Check',
  [testTypes.IRON]: 'Iron Tracking',
  [testTypes.IRON_V2]: 'Iron Tracking',
  [testTypes.NUTRITION_360]: '360° Nutrient & Vitamin Check',
  [testTypes.NUTRITION_360_V2]: '360° Nutrient & Vitamin Check',
  [testTypes.STD_BASICS_ANAL]: 'Anal STD Test',
  [testTypes.STD_BASICS_FEMALE]: 'Female Basics STD Test',
  [testTypes.STD_BASICS_MALE]: 'Male Basics STD Test',
  [testTypes.STD_BASICS_ORAL]: 'Oral STD Test',
  [testTypes.STD_PREMIUM]: 'Premium STD Test',
  [testTypes.HEALTH_CHECK_BASIC]: 'Health Check Basic',
  [testTypes.HEALTH_CHECK_BASIC_V2]: 'Health Check Basic',
  [testTypes.HEALTH_CHECK_BASIC_V3]: 'Health Check Basic',
  [testTypes.HEALTH_RISK_CHECK]: 'Cardiovascular & Diabetes Risk Check',
  [testTypes.HEALTH_CHECK_VEGAN_AND_VEGETARIAN]:
    'Health Check Vegans & Vegetarians',
  [testTypes.HEALTH_CHECK_VEGAN_AND_VEGETARIAN_V2]:
    'Health Check Vegans & Vegetarians',
  [testTypes.HEALTH_CHECK_ATHLETES]: 'Health Check Add-on for Athletes',
  [testTypes.URINARY_TRACT_INFECTION]: 'Urinary Tract Infection Check',
  [testTypes.IMMUNOTHERAPY_CHECK_POLLEN]: 'Immunotherapy Check Pollen',
  [testTypes.MONKEY_POX]: 'Monkey Pox',
  [testTypes.BASIC_ALLERGY_AND_HAYFEVER_CHECK]:
    'Basic Allergy & Hayfever Check',
  [testTypes.ADVANCED_IRON]: 'Advanced Iron Test',
  [testTypes.COLON_CANCER]: 'Colon Cancer Screening',
  [testTypes.ALLERGY_CHECK_FOR_IMMUNOTHERAPY]: '360° Allergy Check'
};
