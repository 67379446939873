import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { ReactComponent as ArrowDownIcon } from '../../../assets/svg/arrow-down-icon.svg';
import { HeaderThemeEnum, HeaderThemeType } from '../types';
import useClickOutside from '../useClickOutside.hook';

import styles from './index.module.scss';

interface LanguageDropdownProps {
  availableLanguages: string[];
  defaultLanguage: string;
  onChangeLanguage: (selectedLanguage: string) => void;
  theme?: HeaderThemeType;
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  availableLanguages,
  defaultLanguage,
  onChangeLanguage,
  theme
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    defaultLanguage
  );

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const handleChange = (newLanguage: string) => {
    //TODO: Check if we need this state or we can use global one that is set
    // with onChangeLanguage
    setSelectedLanguage(newLanguage);
    onChangeLanguage(newLanguage);
    setIsOpen(false);
  };

  const handleLanguageOpen = () => {
    setIsOpen(!isOpen);
  };

  const isThemeColorful = theme === HeaderThemeEnum.COLORFUL;
  return (
    <div
      className={cx({
        [styles.wrapper]: true,
        [styles.wrapperWhite]: isThemeColorful
      })}
      ref={dropdownRef}
    >
      <div
        className={cx({
          [styles.languageWrapper]: true,
          [styles.languageWrapperWhite]: isThemeColorful
        })}
        onClick={handleLanguageOpen}
      >
        {selectedLanguage.toUpperCase()}
        <ArrowDownIcon
          className={cx({
            [styles.arrowDown]: true,
            [styles.arrowUp]: isOpen
          })}
        />
      </div>
      {isOpen && (
        <ul className={styles.dropdown}>
          {availableLanguages.map(language => (
            <li
              className={cx({
                [styles.dropdownItem]: true,
                [styles.dropdownItemColorful]: isThemeColorful
              })}
              key={language}
              onClick={() => handleChange(language)}
            >
              {language.toUpperCase()}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
export default LanguageDropdown;
