import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import LanguageDropdown from '../Header/LanguageDropdown';
import { Body2 } from '../Typography';

import useAuthorization from '../../app/hooks/useAuthorization';
import { useHistory } from 'react-router-dom';
import useClickOutside from '../Header/useClickOutside.hook';

import { ReactComponent as UserIcon } from '../../assets/storybook-placeholders/svg/user-icon.svg';
import { ReactComponent as LogoIcon } from '../../assets/svg/logo-icon.svg';
import { ReactComponent as BackIcon } from '../../assets/svg/arrow-left-icon.svg';

import styles from './index.module.scss';

export interface TestResultsMainHeaderProps {
  availableLanguages: string[];
  defaultLanguage: string;
  onChangeLanguage: (selectedLanguage: string) => void;
  dispatch?: any;
  className?: string;
  logoClassName?: string;
}

const TestResultsMainHeader: React.FC<TestResultsMainHeaderProps> = ({
  availableLanguages,
  defaultLanguage,
  onChangeLanguage,
  dispatch,
  className,
  logoClassName
}) => {
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false);

  const history = useHistory();

  const { signOutUser } = useAuthorization(dispatch);

  const dropdownRef = useRef<HTMLButtonElement>(null);

  useClickOutside(dropdownRef, () => setIsUserDropdownOpen(false));

  const handleUserOpen = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  const handleLogout = () => {
    signOutUser();
  };

  return (
    <div
      className={cx(
        {
          [styles.wrapper]: true,
          [styles.bottomLine]: true
        },
        className
      )}
    >
      <button onClick={() => history.goBack()} className={styles.backIcon}>
        <BackIcon />
      </button>

      <LogoIcon className={cx(logoClassName)} />

      <div className={styles.buttonsWrapper}>
        <LanguageDropdown
          defaultLanguage={defaultLanguage}
          availableLanguages={availableLanguages}
          onChangeLanguage={onChangeLanguage}
        />
        <button
          onClick={handleUserOpen}
          className={styles.userWrapper}
          ref={dropdownRef}
        >
          <UserIcon />
        </button>
        {isUserDropdownOpen && (
          <button onClick={handleLogout} className={styles.userDropdown}>
            <Body2 className={styles.userDropdownItem}>
              <FormattedMessage id='app.logout' defaultMessage='Logout' />
            </Body2>
          </button>
        )}
      </div>
    </div>
  );
};

export default TestResultsMainHeader;
